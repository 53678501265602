@import '../../../../styles/theme.scss';
@import '../../../../styles/layout.scss';

.app-chromaticity {
    position: relative;

    .app-chromaticity-graph-btn {
        position: absolute;
        top: 8px;
        left: 10px;
        padding: 5px !important
    }
    .app-data-view .app-data-view-title {
        padding-left: 45px;
    }

    .app-data-view-row {
        padding-right: 30px;
    }
    .app-data-view-col {
        width: 9%;
        flex: auto !important;
        overflow: hidden;
    }
    .app-data-view-col:first-of-type {
        width: 5%;
    }
}