.app-yes-no-modal {
  position: relative;
  padding: 40px 30px;
  padding-top: 30px;
  display: flex;
  flex-wrap: wrap;
  width: 500px;
  max-width: 100%;
  h3, p, button, input {
      width: 100% !important;
  }
  h3 {
      margin-bottom: 25px;
  }
  p {
      margin-top: 10px;
  }
  button {
      margin-top: 10px;
  }
}