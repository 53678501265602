@import '../../App.scss';
@import '../../styles/theme.scss';
@import '../../styles/layout.scss';

.app-sidebar {
  position: absolute;
  top: 0;
  left: 0;
  width: $app-layout-sidebar-openned-width;
  bottom: 0;
  
  display: flex;
  flex-direction: column;

  .app-sidebar-item {
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 10px;

    cursor: pointer;

    img {
      margin-right: 10px;
    }

    border-left: 3px solid transparent;

    img.active {
      display: none
    }
    img.noactive {
      display: inline-block;
    }

    &.active {
      border-left: 3px solid $app-theme-secondary;
      color: $app-theme-secondary;

      img.active {
        display: inline-block;
      }
      img.noactive {
        display: none;
      }
    }
  }

  .app-sidebar-toggle {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;

    cursor: pointer;

    img {
      position: absolute;
      bottom: 0;
      padding: 10px;
      display: inline-block;
    }

    &.active {
      img {
        right: 0;
      }

      img.active {
        visibility: visible;
        -webkit-transition: all 1s ease-out;
        -moz-transition: all 1s ease-out;
        transition: all 1s ease-out;
      }
      img.noactive {
        visibility: hidden;
      }
    }
    &.noactive {
      img {
        left: 0;
      }

      img.active {
        visibility: hidden;
      }
      img.noactive {
        visibility: visible;
        -webkit-transition: all 1s ease-out;
        -moz-transition: all 1s ease-out;
        transition: all 1s ease-out;
      }
    }
  }
}

@media screen and (min-width: 0) {
  .app-sidebar { 
    .app-sidebar-banner-mobile {
      height: $app-layout-navbar-height;
      background-color: $app-theme-navbar-background;
      display: flex;
      opacity: 0.9;
      .app-logo {
        display: inline-block;
        margin: auto;
      }
    }
  }
}

@media screen and (min-width: $app-layout-md-breakpoint) {
  .app-sidebar { 
    .app-sidebar-item-mobile, .app-sidebar-banner-mobile {
      display: none;
    }
  }
}