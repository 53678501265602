@import '../../../styles/theme.scss';
@import '../../../styles/layout.scss';


.app-products-numbers-block {
  display: flex;
  width: 100%;

  .app-products-numbers-block-items {
    display: flex;
    flex-grow: 1;
    overflow-y: hidden;
    overflow-x: auto;  
    //height: 40px;
    line-height: 40px;

    .app-products-numbers-block-item {

      .app-text-gray {
        font-size: 14px;
      }
      cursor: pointer;
      display: inline-block;
      //height: 30px;
      line-height: 20px;
      padding: 5px 10px;

      margin-right: 5px;
      margin-bottom: 5px;
      margin-top: 5px;

      background: white;
      border-radius: 5px;
      border: 1px solid #ccc;    
      box-shadow: 0 0 5px rgb(0 0 0 / 15%);
      -webkit-box-shadow: 0 0 5px rgb(0 0 0 / 15%);
      -ms-box-shadow: 0 0 5px rgba(0,0,0,.15);

      img {
        height: 16px;
        margin-right: 5px;
      }
    }
  }
}
.app-products-numbers-block-add {
  display: inline-block;
  margin-left: 10px;
  margin-top: 5px;
  cursor: pointer;
}