@import "./theme.scss";

.app-btn-secondary {

  color: white;
  background: $app-theme-secondary;
  border: 1px solid $app-theme-secondary !important;

  &:focus {
    background: $app-theme-secondary;
  }
  


  /* buttons commons*/
  //font-family: Open_Sans;
  font-size: 14px;
  line-height: 16px;
  display: inline-block;
  padding: 10px 30px;
  border-radius: 4px;
  font-weight: 600;
  border: 1px solid black;

  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  &:not(:disabled):not(.disabled) {
    cursor: pointer;
  }
  &:disabled {
    cursor: not-allowed;
  }

  &:hover,
  &:disabled {
    opacity: 0.7;
  }

  &:focus {
    opacity: 1;
    outline: 0;
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(0, 136, 206, 0.6);
  }

  img {
    vertical-align: top;
    height: 16px;
  }
}

.app-btn-primary {

  color: white;
  background: $app-theme-primary;
  border: 1px solid black !important;

  &:focus {
    background: $app-theme-primary;
  }
  


  /* buttons commons*/
  //font-family: Open_Sans;
  font-size: 14px;
  line-height: 16px;
  display: inline-block;
  padding: 10px 30px;
  border-radius: 4px;
  font-weight: 600;
  border: 1px solid black;

  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  &:not(:disabled):not(.disabled) {
    cursor: pointer;
  }
  &:disabled {
    cursor: not-allowed;
  }

  &:hover,
  &:disabled {
    opacity: 0.7;
  }

  &:focus {
    opacity: 1;
    outline: 0;
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(0, 136, 206, 0.6);
  }

  img {
    vertical-align: top;
    height: 16px;
  }
}


.app-btn-neutral {

  color: rgb(129, 126, 126);
  background: #f7f7f7;
  border: 1px solid gray !important;

  &:focus {
    background: white;
  }
  


  /* buttons commons*/
  //font-family: Open_Sans;
  font-size: 14px;
  line-height: 16px;
  display: inline-block;
  padding: 10px 30px;
  border-radius: 4px;
  font-weight: 600;
  border: 1px solid black;

  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  &:not(:disabled):not(.disabled) {
    cursor: pointer;
  }
  &:disabled {
    cursor: not-allowed;
  }

  &:hover,
  &:disabled {
    opacity: 0.7;
  }

  &:focus {
    opacity: 1;
    outline: 0;
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(0, 136, 206, 0.6);
  }

  img {
    vertical-align: top;
    height: 16px;
  }
}
