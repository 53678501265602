@import '../../../../styles/theme.scss';
@import '../../../../styles/layout.scss';


@media screen and (min-width: 0) {
    .app-color-del-btn {
        width: 100%;
    }
  }
  @media screen and (min-width: $app-layout-md-breakpoint) {
    .app-color-del-btn {
      width: auto;
    }
  }