@import './styles/theme.scss';
@import './styles/layout.scss';

$app-layout-navbar-height: 50px;
$app-layout-sidebar-width: 47px;
$app-layout-sidebar-openned-width: 280px;

$app-layout-navbar-zindex: 1;
$app-layout-sidebar-zindex: 2;
$app-layout-main-zindex: 1;

.app-layout {

  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  .app-layout-navbar{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: $app-layout-navbar-height;
    
    background-color: $app-theme-navbar-background;
    color: $app-theme-navbar-text;
  }
  .app-layout-main {
    position: absolute;
    background-color: $app-theme-main-background;
    color: $app-theme-main-text;
  }
  .app-layout-sidebar {
    position: absolute;
    overflow: hidden;
    z-index: $app-layout-sidebar-zindex;
  }
}

.app-layout-loading {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}


@media screen and (min-width: 0) {
  .app-layout {
    .app-layout-sidebar {
      display: none;
    }

    .app-layout-main {
      top: $app-layout-navbar-height;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }

  .app-layout-sidebar-mobile {
    display: flex;
    
    position: fixed;
    top: 0;
    left: -$app-layout-sidebar-openned-width;
    bottom: 0;
    width: $app-layout-sidebar-openned-width;
    
    background-color: $app-theme-sidebar-background;
    color: $app-theme-sidebar-text;
      
    border-right: 1px solid rgb(201, 201, 201);
    -webkit-transition: all 0.25s ease-out;
    -moz-transition: all 0.25s ease-out;
    transition: all 0.25s ease-out;
  }
  .app-layout-sidebar-backdrop-mobile {
    display: inline-block;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: black;
    opacity: 0.3;
  }
  .app-layout-sidebar-mobile-expand {
    left: 0;
  }
}

@media screen and (min-width: $app-layout-md-breakpoint) {
  .app-layout {

    .app-layout-sidebar {
      display: flex;

      top: $app-layout-navbar-height;
      left: 0;
      bottom: 0;
      width: $app-layout-sidebar-width;
      
      background-color: $app-theme-sidebar-background;
      color: $app-theme-sidebar-text;
      
      border-right: 1px solid rgb(202, 202, 202);      
      box-shadow: 0 0 20px rgb(0 0 0 / 15%);
      -webkit-box-shadow: 0 0 20px rgb(0 0 0 / 15%);
      -ms-box-shadow: 0 0 20px rgba(0,0,0,.15);

      -webkit-transition: all 0.25s ease-out;
      -moz-transition: all 0.25s ease-out;
      transition: all 0.25s ease-out;
    }
    .app-layout-main {
      top: $app-layout-navbar-height;
      left: $app-layout-sidebar-width;
      right: 0;
      bottom: 0;

      -webkit-transition: all 0.25s ease-out;
      -moz-transition: all 0.25s ease-out;
      transition: all 0.25s ease-out;
    }
  }
  .app-layout-expand {
  
    .app-layout-sidebar {
      width: $app-layout-sidebar-openned-width;
    }
    .app-layout-main {
      left: $app-layout-sidebar-openned-width;
    }
  }
  .app-layout-sidebar-mobile {
    display: none;
  }
  .app-layout-sidebar-backdrop-mobile {
    display: none;
  }
}