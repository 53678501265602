@import '../../../styles/theme.scss';
@import '../../../styles/layout.scss';

.app-chromaticity-graph-target-polygon {

  display: flex;
  flex-wrap: wrap;
  
  @media screen and (min-width: 0) {
    width: 100%;
    padding-right: 0px;
  }
  @media screen and (min-width: $app-layout-md-breakpoint) {
    width: 50%;
    padding-right: 10px;
  }

  .app-chromaticity-graph-target-polygon-100 {
    width: 100%;
  }
  .app-chromaticity-graph-target-polygon-column {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }
  .app-chromaticity-graph-target-polygon-33 {
    width: 33.33%;
  }
  input {
    width: 100%;
  }
}