@import '../../styles/layout.scss';
@import '../../styles/theme.scss';

.app-bining {

    @media screen and (min-width: 0) {
        .app-bining-control {
            width: 100%;
            button, input, select {
                width: 100%;
                margin-bottom: 5px;
            }
        }
      }
      @media screen and (min-width: $app-layout-md-breakpoint) {
        .app-bining-control {
            width: auto;
            button, input, select {
                width: auto;
                margin-bottom: 5px;
                margin-right: 5px;
            }
            input, select {
                margin-right: 10px;
            }
        }
      }

    .app-bining-file-upload {
        display: none;
    }
}
