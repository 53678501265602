
////////////////////// auto //////////////////////////
.mA {
	margin: auto !important;
}
.mtA {
	margin-top: auto !important;
}
.mbA {
	margin-bottom: auto !important;
}
.mlA {
	margin-left: auto !important;
}
.mrA {
	margin-right: auto !important;
}

////////////////////// 0px //////////////////////////
.m0 {
	margin: 0px !important;
}
.mt0 {
	margin-top: 0px !important;
}
.mb0 {
	margin-bottom: 0px !important;
}
.ml0 {
	margin-left: 0px !important;
}
.mr0 {
	margin-right: 0px !important;
}

////////////////////// 5px //////////////////////////
.m5 {
	margin: 5px !important;
}
.mt5 {
	margin-top: 5px !important;
}
.mb5 {
	margin-bottom: 5px !important;
}
.ml5 {
	margin-left: 5px !important;
}
.mr5 {
	margin-right: 5px !important;
}

////////////////////// 10px //////////////////////////
.m10 {
	margin: 10px !important;
}
.mt10 {
	margin-top: 10px !important;
}
.mb10 {
	margin-bottom: 10px !important;
}
.ml10 {
	margin-left: 10px !important;
}
.mr10 {
	margin-right: 10px !important;
}

////////////////////// 15px //////////////////////////
.m15 {
	margin: 15px !important;
}
.mt15 {
	margin-top: 15px !important;
}
.mb15 {
	margin-bottom: 15px !important;
}
.ml15 {
	margin-left: 15px !important;
}
.mr15 {
	margin-right: 15px !important;
}

////////////////////// 20px //////////////////////////
.m20 {
	margin: 20px !important;
}
.mt20 {
	margin-top: 20px !important;
}
.mb20 {
	margin-bottom: 20px !important;
}
.ml20 {
	margin-left: 20px !important;
}
.mr20 {
	margin-right: 20px !important;
}

////////////////////// 30px //////////////////////////
.m30 {
	margin: 30px !important;
}
.mt30 {
	margin-top: 30px !important;
}
.mb30 {
	margin-bottom: 30px !important;
}
.ml30 {
	margin-left: 30px !important;
}
.mr30 {
	margin-right: 30px !important;
}

////////////////////// 40px //////////////////////////
.m40 {
	margin: 40px !important;
}
.mt40 {
	margin-top: 40px !important;
}
.mb40 {
	margin-bottom: 40px !important;
}
.ml40 {
	margin-left: 40px !important;
}
.mr40 {
	margin-right: 40px !important;
}
