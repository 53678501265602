@import '../../styles/layout.scss';
@import '../../styles/theme.scss';

.app-manufacturers {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  display: flex;

  .app-manufacturers-nav-map {
    position: absolute;
    top: 0;
    left: 0;
    width: 350px;
    bottom: 0;

    background: white;
    border-right: 1px solid #ddd;    
    box-shadow: 0 0 20px rgb(0 0 0 / 15%);
    -webkit-box-shadow: 0 0 20px rgb(0 0 0 / 15%);
    -ms-box-shadow: 0 0 20px rgba(0,0,0,.15);

    display: flex;
    flex-direction: column;

    .app-manufacturers-nav-map-wrapper {
      position: relative;
      flex-grow: 1;
    }
    .app-manufacturers-nav-map-buttons {
      position: relative;
      width: 100%;
      padding: 10px;
      display: flex;
      flex-wrap: wrap;
      button {
        margin-bottom: 5px;
        min-width: 100%;
      }
    }
  }

  .app-manufacturers-content {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;

    display: flex;
    flex-wrap: wrap;
    align-items:flex-start;
    align-content:flex-start;

    overflow-x: hidden;
    overflow-y: auto;

    .app-manufacturers-header {
      background: white;
      border-bottom: 1px solid rgb(202, 202, 202);      
      box-shadow: 0 0 20px rgb(0 0 0 / 15%);
      -webkit-box-shadow: 0 0 20px rgb(0 0 0 / 15%);
      -ms-box-shadow: 0 0 20px rgba(0,0,0,.15);

      .app-manufacturers-header-title {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        .app-manufacturers-header-title-text {
          font-size: 20px;
          font-weight: bold;
          flex-grow: 1;
          .app-manufacturers-header-title-text-active {
            font-size: 26px;
            color: $app-theme-secondary;
          }
        }
      }
    }

    .app-manufacturers-header-mobile {
      span {margin-left: auto;}
      margin-bottom: 15px;
    }

    .app-manufacturers-header {
      width: 100%;
      padding: 20px;
    }

  }
}

@media screen and (min-width: 0) {

  .app-manufacturers-header-mobile {
    display: flex !important;
  }
  .app-manufacturers-content {
    left: 0;
  }

  .app-manufacturers-header-nav-map-switch-mobile {
    display: inline-block;
  }
  .app-manufacturers-header-subtitle {
    margin-top: 10px;
  }

  .app-manufacturers-header-title {

    .app-manufacturer-add-btn {
      margin-bottom: 10px;
    }
  }
  
}

@media screen and (min-width: $app-layout-sm-breakpoint) {
}

@media screen and (min-width: $app-layout-md-breakpoint) {
  .app-manufacturers-header-mobile {
    display: none !important;
  }

  .app-manufacturers-content {
    left: 350px;
  }
  .app-manufacturers-header-nav-map-switch-mobile {
    display: none;
  }
  .app-manufacturers-header-subtitle {
    margin-top: 2px;
  }

  .app-manufacturers-header-title {

    .app-manufacturer-add-btn {
      margin-right: 10px;
      margin-bottom: 0;
    }
  }
  
}

@media screen and (min-width: $app-layout-lg-breakpoint) {

}