@import '../../../styles/theme.scss';
@import '../../../styles/layout.scss';

.app-chromaticity-graph-active-bins {

  display: inline-block;
  
  @media screen and (min-width: 0) {
    width: 100%;
    padding: 0px;
  }
  @media screen and (min-width: $app-layout-md-breakpoint) {
    width: 33%;
    padding: 10px;
  }

  .app-chromaticity-graph-active-bins-header {
    min-height: 40px;
  }

  .app-chromaticity-graph-active-bins-list {
    background: rgb(249, 249, 249);
    border: 1px solid rgb(199, 199, 199);
    border-radius: 3px;
    height: 175px;
    overflow: auto;

    div {
      cursor: pointer;
      color: gray;
      background: #eee;
      padding: 5px 20px;
      border-bottom: 1px solid rgb(199, 199, 199);

      &.active {
        color: black;
        background: rgba(0, 255, 0, 0.1);
      }
      &.activeinner {
        color: black;
        background: rgba(102, 225, 225, 0.3);
      }
    }
  }
}