@import '../../../styles/theme.scss';
@import '../../../styles/layout.scss';

.app-product-channel-details-common-properties {
  padding: 10px;
  display: flex;
  flex-wrap: wrap;

  .app-product-channel-details-common-properties-block {
    padding: 10px;
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;

    @media screen and (min-width: 0) {
      width: 100%;
    }

    @media screen and (min-width: $app-layout-md-breakpoint) {
      width: 50%;
    }

    h6 {
      width: 100%;
    }

    .app-product-channel-details-common-properties-block-item {
      display: flex;
      flex-wrap: nowrap;
      align-items: center;

      span {
        padding-left: 2px !important;
      }
    }
  }
}