@import '../../styles/layout.scss';
@import '../../styles/theme.scss';

.app-products {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  display: flex;

  .app-products-nav-map {
    position: absolute;
    top: 0;
    left: 0;
    width: 350px;
    bottom: 0;

    background: white;
    border-right: 1px solid #ddd;    
    box-shadow: 0 0 20px rgb(0 0 0 / 15%);
    -webkit-box-shadow: 0 0 20px rgb(0 0 0 / 15%);
    -ms-box-shadow: 0 0 20px rgba(0,0,0,.15);

    display: flex;
    flex-direction: column;

    
    .app-products-nav-map-wrapper {
      position: relative;
      flex-grow: 1;
    }
    .app-products-nav-map-buttons {
      position: relative;
      width: 100%;
      padding: 10px;
      display: flex;
      button {
        min-width: 100%;
      }
    }
  }

  .app-products-nav-map-hide {
    @media screen and (min-width: 0) {
      display: none !important;
    }
    @media screen and (min-width: $app-layout-md-breakpoint) {
      display: flex !important;
    }
  }

  .app-products-content {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    @media screen and (min-width: 0) {
      left: 0;
    }
    @media screen and (min-width: $app-layout-md-breakpoint) {
      left: 350px
    }

    display: flex;
    flex-wrap: wrap;
    align-items:flex-start;
    align-content:flex-start;

    overflow-x: hidden;
    overflow-y: auto;

    .app-products-header-mobile {
      span {margin-left: auto;}
      margin-bottom: 15px;
      height: 45px;
      width: 100%;

      span {
        position: absolute;
        right: 20px;
        top: 20px;

        img {
          width: 20px;
        }
      }

      @media screen and (min-width: 0) {
        display: flex !important;
      }
      @media screen and (min-width: $app-layout-md-breakpoint) {
        display: none !important;
      }
    }

    .app-products-header {
      width: 100%;
      padding: 20px;

      background: white;
      border-bottom: 1px solid rgb(202, 202, 202);      
      box-shadow: 0 0 20px rgb(0 0 0 / 15%);
      -webkit-box-shadow: 0 0 20px rgb(0 0 0 / 15%);
      -ms-box-shadow: 0 0 20px rgba(0,0,0,.15);

      .app-products-header-title {
        display: flex;
        flex-wrap: wrap;
        align-items: center;

        .app-products-header-subtitle {
          @media screen and (min-width: 0) {
              margin-top: 10px;
          }
          @media screen and (min-width: $app-layout-md-breakpoint) {
              margin-top: 2px;
          }
        }

        .app-products-header-title-text {
          font-size: 20px;
          font-weight: bold;
          flex-grow: 1;
          .app-products-header-title-text-active {
            font-size: 26px;
            color: $app-theme-secondary;
          }
        }
      }
    }
  }
}
