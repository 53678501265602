
.app-loader {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
    
  display: flex;
  background-color: black;
  color: white;
  opacity: 0.8;

  .app-loader-content {
    display: inline-block;
    text-align: center;
    margin: auto;

    img {
      height: 30px;
    }
  }
}