.app-query {
    .app-query-filter {
        display: flex;
        align-items: flex-end;
    }

    .app-query-list {
        width: 300px;
        min-height: 450px;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        position: relative;
        margin-right: 20px;
        background: white;
        border: 1px solid rgb(202, 202, 202);      
        box-shadow: 0 0 20px rgb(0 0 0 / 15%);
        -webkit-box-shadow: 0 0 20px rgb(0 0 0 / 15%);
        -ms-box-shadow: 0 0 20px rgba(0,0,0,.15);

        .app-query-list-wrapper {
            width: 100%;
            position: relative;
            flex-grow: 1;
        }

        .app-query-list-buttons {
            width: 100%;
            padding: 10px;
            display: flex;
            button {
              min-width: 100%;
            }
        }
    }

    .app-query-data {
        display: flex;
        flex-wrap: wrap;
        position: relative;
        left: 0;
        height: 100%;
        width: calc(100% - 300px - 60px);
    }
}