

.app-chromaticity-graph-wrapper {
  .app-chromaticity-graph {
    display: flex;
  
    width: 700px;
    max-width: 700px;
    height: auto;
    max-height: 700px;
    aspect-ratio: 1 / 1;
    margin: auto;
  
    canvas {
      width: 100%;
      height: 100%;
    }
  }
  .app-chromaticity-graph-options {
    display: flex;
    flex-wrap: wrap;

    .app-chromaticity-graph-target-polygon-label {
      width: 100%;  
      border-bottom: 1px solid #d5d5d5;
      padding-bottom: 10px;
      font-size: 14px;
      display: flex;
    
      .app-chromaticity-graph-target-polygon-label-item-inner {
        color: #6acccc;
        span {
          display: inline-block;
          height: 12px;
          width: 12px;
          border-radius: 50%;
          margin-right: 5px;
          vertical-align: baseline;
          border: 1px solid #6acccc;
          background-color: rgba(102, 225, 225, 0.3);
        }
      }

      .app-chromaticity-graph-target-polygon-label-item-active {
        color: #5ec65e;
        span {
          display: inline-block;
          height: 12px;
          width: 12px;
          border-radius: 50%;
          margin-right: 5px;
          vertical-align: baseline;
          border: 1px solid #5ec65e;
          background-color: rgba(0, 255, 0, 0.1);
        }
      }

      .app-chromaticity-graph-target-polygon-label-item-ta {
        color: rgba(0, 0, 155, 1);
        span {
          display: inline-block;
          height: 12px;
          width: 12px;
          border-radius: 50%;
          margin-right: 5px;
          vertical-align: baseline;
          border: 1px solid #00009b;
          background-color: rgba(0, 0, 155, 0.1);
        }
      }
    }
  }
}
