@import '../../../styles/theme.scss';
@import '../../../styles/layout.scss';

.app-product-copy {
    position: relative;
    padding: 40px 30px;
    padding-top: 30px;
    display: flex;
    flex-wrap: wrap;
    width: 500px;
    max-width: 100%;
    h3, p, button, input, select {
        width: 100% !important;
    }
    p {
        margin-top: 10px;
        margin: 0;
        margin-top: 15px !important;
    }
    input, select {
        margin: 0 !important;
    }
    button {
        margin-top: 25px;
    }
    .app-product-copy-list {
        width: 100%;
        max-width: 100%;
        max-height: 350px;

        .app-nav-map {
            position: relative;
            display: flex;
            flex-direction: column;
            overflow-y: auto;
            max-height: 100%;
            background: white;
            border: 1px solid rgb(202, 202, 202);      
            box-shadow: 0 0 20px rgb(0 0 0 / 15%);
            -webkit-box-shadow: 0 0 20px rgb(0 0 0 / 15%);
            -ms-box-shadow: 0 0 20px rgba(0,0,0,.15);

            .app-nav-map-filter{
                padding: 10px 20px 0px 10px;
            }
        }
    }
}
@media screen and (min-width: 0) {
    .app-product-copy-btn {
        width: 100%;
    }
}
@media screen and (min-width: $app-layout-md-breakpoint) {
   .app-product-copy-btn {
      width: auto;
    }
}