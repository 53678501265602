@import '../../../styles/theme.scss';
@import '../../../styles/layout.scss';

.app-products-channels-add {
  min-width: 320px;
  width: 400px;
  position: relative;
  overflow: hidden;

  display: flex;
  flex-direction: column;
  padding-bottom: 20px;

  h3, p, button, input, select {
    width: 100% !important;
  }

  $app-products-channels-add-title-height: 40px;
  .app-products-channels-add-title {
    font-size: 18px;
    font-weight: bold;
    padding: 15px;
    padding-bottom: 5px;

    display: inline-block;
  }
  .app-products-channels-add-content {
    overflow-x: hidden;
    overflow-y: auto;
    
    display: flex;
    flex-direction: column;

    input {
      width: 100%;
    }
  }
}

.app-products-channels-block {
  display: flex;
  width: 100%;

  .app-products-channels-block-items {
    display: flex;
    flex-grow: 1;
    overflow-y: hidden;
    overflow-x: auto;  
    height: 40px;
    line-height: 38px;
    background: #eee;
    border: 1px solid #ddd;
    border-radius: 3px;

    .app-products-channels-block-item {
      cursor: pointer;
      display: inline-block;
      height: 30px;
      line-height: 28px;
      padding-left: 10px;
      padding-right: 10px;

      margin-left: 5px;
      margin-top: 3px;

      background: white;
      border-radius: 5px;
      border: 1px solid #ccc;    
      box-shadow: 0 0 5px rgb(0 0 0 / 15%);
      -webkit-box-shadow: 0 0 5px rgb(0 0 0 / 15%);
      -ms-box-shadow: 0 0 5px rgba(0,0,0,.15);

      img {
        height: 16px;
      }

      &.active {
        color: #ff375f;
        border-color: #ff375f;
      }
    }
  }
  .app-products-channels-block-add {
    display: inline-block;
    margin-left: 10px;
    margin-top: 5px;
    cursor: pointer;
  }
}


.app-products-channels-order {
  display: flex;
  flex-direction: column;

  padding: 20px;
  width: 400px;
  overflow: hidden;
  position: relative;

  p, button{
    width: 100% !important;
  }

  .app-products-channels-order-title {
    font-size: 18px;
    font-weight: bold;
  }

  .app-products-channels-order-list {
    .app-products-channels-order-list-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 3px 0;
      border-bottom: 1px solid #ddd;

      &:last-child {
        border-bottom: none;
      }

      .app-products-channels-order-list-controls {
        button {
          background: none;
          border: none;
          cursor: pointer;
          padding: 1px;
          font-size: 16px;
          margin-left: 2px;

          &:disabled {
            cursor: not-allowed;
          }
        }
      }
    }
  }
}