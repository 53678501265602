@import '../../styles/theme.scss';
@import '../../styles/layout.scss';

.app-navbar {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  display: flex;

  span {
    display: inline-block;
  }

  .app-navbar-title {
    margin-top: 6px;
    margin-left: 15px;
  }

  .app-navbar-profile {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;

    .dropdown-toggle {
      background: transparent;
      border: none;
      height: 100%;
      img {
        padding-right: 5px;
      }

      &:focus, &:active {
        box-shadow: none !important;
      }
    }
    .dropdown-item {
      text-align: center;
    }
  }

  .app-navbar-menu-mobile {
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 10px;
    cursor: pointer;
  }
}


@media screen and (min-width: 0) {
  .app-navbar {
    .app-navbar-title, .app-navbar-profile {
      display: none;
    }
  }
  .app-navbar-title-mobile {
    display: inline-block;
    margin: auto;
  }
}

@media screen and (min-width: $app-layout-md-breakpoint) {
  .app-navbar {
    .app-navbar-title, .app-navbar-profile {
      display: inline-block;
    }
    .app-navbar-title-mobile, .app-navbar-menu-mobile {
      display: none;
    }
  }
}