.app-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10000;
  overflow-x: hidden;
  overflow-y: auto;
  
  padding-bottom: 40px;

  display: flex;

  .app-modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: black;
    opacity: 0.6;
  }

  .app-modal-content {
    position: relative;
    margin: 150px auto auto auto;
    box-shadow: 0 0 20px rgb(0 0 0 / 15%);
    -webkit-box-shadow: 0 0 20px rgb(0 0 0 / 15%);
    -ms-box-shadow: 0 0 20px rgba(0,0,0,.15);

    background-color: white;
    border-radius: 5px;
    z-index: 10001;

    max-width: calc(100% - 20px);

    .app-modal-content-close {
      position: absolute;
      top: 7px;
      right: 7px;
      cursor: pointer;
    }
  }
}