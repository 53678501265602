@import '../../../styles/theme.scss';


.app-data-view {
  position: relative;
  overflow: hidden;

  display: flex;
  flex-direction: column;

  $app-data-view-title-height: 40px;
  .app-data-view-title {
    font-size: 18px;
    font-weight: bold;
    padding: 15px;
    padding-bottom: 5px;

    display: inline-block;

    img {
      cursor: pointer;
      height: 20px;
      top: 15px;
      right: 5px;
      position: absolute;
    }
  }
  .app-data-view-content {
    overflow-x: hidden;
    overflow-y: hidden;
    width: 100%;
    display: inline-block;
  }
  .app-data-view-content-scroll {
    overflow-x: auto;
    overflow-y: auto;
    
    display: inline-block;
  }

  .app-data-view-row {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    padding-right: 10px;

    .app-data-view-col {
      flex: 1 1 0px;
      padding: 5px 15px;
    }

    img {
      cursor: pointer;
      height: 20px;
      top: 5px;
      right: 5px;
      position: absolute;
    }
    border-bottom: 1px solid #ddd;
  }

  .app-data-view-row-hover {
    cursor: pointer;
    &:hover {
      opacity: 0.8;
      background: rgb(253, 253, 253);
    }
  }
}