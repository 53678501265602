@import '../../../styles/theme.scss';
@import '../../../styles/layout.scss';

.app-product-channel-details {

  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding: 15px;
  padding-bottom: 0;
  
  .app-product-channel-details-left {
    @media screen and (min-width: 0) {
      width: 100%;
    }
    @media screen and (min-width: $app-layout-md-breakpoint) {
      width: 75%;
    }
    display: flex;
    flex-wrap: wrap;
  }
  .app-product-channel-details-container-x {
    float: right;
    cursor: pointer;
  }
  .app-product-channel-details-right {
    @media screen and (min-width: 0) {
      width: 100%;
    }
    @media screen and (min-width: $app-layout-md-breakpoint) {
      width: 25%;
    }
    display: flex;
    flex-wrap: wrap;
  }

  .app-product-channel-details-container { 
    position: relative;
    background: white;
    border: 1px solid rgb(202, 202, 202);      
    box-shadow: 0 0 20px rgb(0 0 0 / 15%);
    -webkit-box-shadow: 0 0 20px rgb(0 0 0 / 15%);
    -ms-box-shadow: 0 0 20px rgba(0,0,0,.15);

    padding: 15px;
    margin-bottom: 15px;
    width: 100%;
  }

  .app-product-channel-details-shortlisted { 
    flex-grow: 1;

    @media screen and (min-width: 0) {
      margin-top: 20px;
      margin-left: 0px;
    }
    @media screen and (min-width: $app-layout-md-breakpoint) {
      margin-top: 0px;
      margin-left: 15px;
    }
  }

  .app-chromaticity-graph {
    width: 100% !important;
  }
  
  .app-product-channel-details-save-btn {
    position: absolute;
    top: 15px;
    right: 15px;
    padding: 7px 13px;
  }
}