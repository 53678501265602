@import '../../../styles/theme.scss';
@import '../../../styles/layout.scss';

.app-query-details {

    left: 0 !important;
    border: 1px solid rgb(202, 202, 202);      
    box-shadow: 0 0 20px rgb(0 0 0 / 15%);
    -webkit-box-shadow: 0 0 20px rgb(0 0 0 / 15%);
    -ms-box-shadow: 0 0 20px rgba(0,0,0,.15);

    @media screen and (min-width: 0) {
        .app-bining-control {
            width: 100%;
            button, input, select {
                width: 100%;
                margin-bottom: 5px;
            }
        }
      }
      @media screen and (min-width: $app-layout-md-breakpoint) {
        .app-bining-control {
            width: auto;
            button, input, select {
                width: auto;
                margin-bottom: 5px;
                margin-right: 5px;
            }
            input, select {
                margin-right: 10px;
            }
        }
      }

    .app-bining-file-upload {
        display: none;
    }
}