
$app-theme-primary: #00df96;
$app-theme-secondary: #ff375f;

$app-theme-navbar-background: #262629;
$app-theme-navbar-text: white;


$app-theme-sidebar-background: white;
$app-theme-sidebar-text: black;


$app-theme-main-background: #eee;
$app-theme-main-text: black;