@import '../../../styles/theme.scss';
@import '../../../styles/layout.scss';

.app-binning-results {
    /* Reset default table styles */
    table {
      border-collapse: collapse;
      width: 100%;
      overflow: auto;
      display: block;
    }
  
    /* Table header */
    th {
      background-color: #f2f2f2;
      font-weight: bold;
      text-align: left;
      padding: 8px;
      border: 1px solid #ddd;
    }
  
    /* Table rows */
    tr {
      background-color: #ffffff;
    }
  
    /* Alternating row colors */
    tr:nth-child(even) {
      background-color: #f2f2f2;
    }
  
    /* Table cell styles */
    td {
      padding: 8px;
      border: 1px solid #ddd;
    }
}

.app-result {
  display: flex;
  height: 100%;
  width: 100%;

  padding-top: 5px;
  max-height: 500px;
  overflow-x: auto;
  overflow-y: auto;

  .app-result-item {
    background: white;
    padding: 15px;
    padding-top: 5px;
    border-radius: 5px;
    margin: 5px;
    margin-left: 0;
    min-width: 200px;
    width: 200px;

    border: 1px solid #cacaca;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
    -webkit-box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
    -ms-box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);

    color: gray;
    b {
      color: black;
    }
    hr {
      margin: 5px;
    }
  }
}