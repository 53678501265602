@import '../../../styles/theme.scss';
@import '../../../styles/layout.scss';

.app-product-channel-shortlisted-bins {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  position: absolute;
  width: calc(100% - 30px);
  height: calc(100% - 30px);

  h4 {
    width: 100%;
  }

  .app-product-channel-shortlisted-bins-content {
    flex: 1;
    width: 100%;
    color: gray;
    flex-grow: 1;
    background: #eee;
    border: 1px solid lightgray;
    padding: 10px;
    overflow-x: hidden;
    overflow-y: auto;
  }
}