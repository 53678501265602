.app-border-darkred {
    border-color: darkred !important;
}
.app-border-red {
    border-color: red !important;
}
.app-cursor-pointer {
    cursor: pointer;
}
.app-width-100 {
    width: 100%;
}

.app-flex {
    display: flex;
    flex-wrap: wrap;

    .app-flex-50 {
        width: 50%;
    }
    .app-flex-100 {
        width: 100%;
    }
    .app-flex-33 {
        width: 33%;
    }
}

.app-w-50 {
    width: 50%;
}
.app-w-100 {
    width: 100%;
}
.app-w-33 {
    width: 33%;
}

.app-no-permission-content {
    display: inline-block;
    position: absolute;
    text-align: center;
    color:gray;
      
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}