@import "./theme.scss";

select.app-input {
  background-image   : url('../../public/assets/img/icons/chevron-down-gray.svg');
  background-repeat  : no-repeat, repeat;
  background-position: right 5px top 8px, 0 0;
  background-size    : 18px;
  padding-right: 30px;

  -moz-appearance   : none;
  -webkit-appearance: none;

  &::-ms-expand {
    display: none;
  }

  // all text unselectable
  user-select        : none;
  -moz-user-select   : none;
  -khtml-user-select : none;
  -webkit-user-select: none;
  -o-user-select     : none;
}

.app-input {
  //font-family       : Open_Sans;
  border            : 1px solid rgb(163, 163, 163);
  border-radius     : 6px;
  padding           : 6px 15px;
  background        : transparent;
  -webkit-box-sizing: border-box;
  /* Safari/Chrome, other WebKit */
  -moz-box-sizing   : border-box;
  /* Firefox, other Gecko */
  box-sizing        : border-box;

  &:focus {
    outline   : 0;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(0, 136, 206, 0.6);
  }

  &:disabled {
    background: #eee;
    opacity   : 0.8;
  }

  &.error {
    outline: 0 !important;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 5px rgba(253, 1, 1, 0.6) !important;
  }
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: gray;
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: gray;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: gray;
}

input:-webkit-autofill,
/* autofill input */
input:-webkit-autofill:hover,
input:-webkit-autofill:focus input:-webkit-autofill,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-text-fill-color: #000;
  box-shadow             : 0 0 0px 1000px transparent inset;
  -webkit-box-shadow     : 0 0 0px 1000px transparent inset;
  transition             : background-color 5000s ease-in-out 0s;
}
