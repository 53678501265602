@import '../../../styles/theme.scss';
@import '../../../styles/layout.scss';



.app-products-folder {

  width: 100%;
  padding: 20px;
  padding-right: 5px;
  
  .app-products-folder-item {
    display: inline-block;
    position: relative;
    height: 100px;
    padding: 20px 30px;
    text-align: center;

    span {
      margin: auto;
      overflow: hidden;
    }

    div {
      cursor: pointer;
      position: absolute;
      width: calc(100% - 15px);
      height: calc(100% - 15px);
      left: 0;
      top: 0;
      border: 1px solid gray;
      border-radius: 5px;
      padding: 10px;
      display: flex;
      background: white;
    }
  }
}
@media screen and (min-width: 0) {
  .app-products-folder-item {
    width: 100%;
  }  
}

@media screen and (min-width: $app-layout-sm-breakpoint) {
}

@media screen and (min-width: $app-layout-md-breakpoint) {
  .app-products-folder-item {
    width: 50%;
  }
}

@media screen and (min-width: $app-layout-lg-breakpoint) {
  .app-products-folder-item {
    width: 33.3%;
  }
}

@media screen and (min-width: $app-layout-hd) {
  .app-products-folder-item {
    width: 25%;
  }
}

@media screen and (min-width: $app-layout-full-hd) {
  .app-products-folder-item {
    width: 20%;
    max-width: 800px;
  }
}