
@import "./theme.scss";

@font-face {
  font-family: interactRoboto;
  src: url('../../public/assets/fonts/Roboto/Roboto-Regular.ttf');
}

// GLOBALS
body {
    font-family: interactRoboto;

    font-size: 14px;
    line-height: 20px;
  
    //sizes for element
    h1 {
      font-size: 40px;
      line-height: 50px;
    }
  
    h2 {
      font-size: 25px;
      line-height: 33px;
    }
  
    h3 {
      font-size: 24px;
      line-height: 31px;
    }
  
    h4 {
      font-size: 18px;
      line-height: 24px;
    }
  
    h1, h2, h3, h4, p {
      margin: 0;
    }
  }

  .app-text-gray {
    color: gray;
  }
  .app-text-darkred {
    color: darkred;
  }
  .app-text-secondary {
    color: $app-theme-secondary;
  }
  .app-text-center {
    text-align: center;
  }
  .app-text-white {
    color: white;
  }