////////////////////// 0px //////////////////////////
.p0 {
	padding: 0px !important;
}
.pt0 {
	padding-top: 0px !important;
}
.pb0 {
	padding-bottom: 0px !important;
}
.pl0 {
	padding-left: 0px !important;
}
.pr0 {
	padding-right: 0px !important;
}

////////////////////// 5px //////////////////////////
.p5 {
	padding: 5px !important;
}
.pt5 {
	padding-top: 5px !important;
}
.pb5 {
	padding-bottom: 5px !important;
}
.pl5 {
	padding-left: 5px !important;
}
.pr5 {
	padding-right: 5px !important;
}

////////////////////// 10px //////////////////////////
.p10 {
	padding: 10px !important;
}
.pt10 {
	padding-top: 10px !important;
}
.pb10 {
	padding-bottom: 10px !important;
}
.pl10 {
	padding-left: 10px !important;
}
.pr10 {
	padding-right: 10px !important;
}

////////////////////// 15px //////////////////////////
.p15 {
	padding: 15px !important;
}
.pt15 {
	padding-top: 15px !important;
}
.pb15 {
	padding-bottom: 15px !important;
}
.pl15 {
	padding-left: 15px !important;
}
.pr15 {
	padding-right: 15px !important;
}

////////////////////// 20px //////////////////////////
.p20 {
	padding: 20px !important;
}
.pt20 {
	padding-top: 20px !important;
}
.pb20 {
	padding-bottom: 20px !important;
}
.pl20 {
	padding-left: 20px !important;
}
.pr20 {
	padding-right: 20px !important;
}

////////////////////// 25px //////////////////////////
.p25 {
	padding: 25px !important;
}
.pt25 {
	padding-top: 25px !important;
}
.pb25 {
	padding-bottom: 25px !important;
}
.pl25 {
	padding-left: 25px !important;
}
.pr25 {
	padding-right: 25px !important;
}

////////////////////// 30px //////////////////////////
.p30 {
	padding: 30px !important;
}
.pt30 {
	padding-top: 30px !important;
}
.pb30 {
	padding-bottom: 30px !important;
}
.pl30 {
	padding-left: 30px !important;
}
.pr30 {
	padding-right: 30px !important;
}

////////////////////// 40px //////////////////////////
.p40 {
	padding: 40px !important;
}
.pt40 {
	padding-top: 40px !important;
}
.pb40 {
	padding-bottom: 40px !important;
}
.pl40 {
	padding-left: 40px !important;
}
.pr40 {
	padding-right: 40px !important;
}
