@import '../../../styles/theme.scss';
@import '../../../styles/layout.scss';

.app-product-bining {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  height: 100%;
  width: 100%;

  margin: 20px;
  max-height: 500px;
  overflow-x: hidden;
  overflow-y: auto;

  .app-product-bining-items {
    margin-bottom: 3px;

    input {
      padding-top: 3px;
      padding-bottom: 3px;
      width: 150px;
    }
  }
}