@import '../../../../styles/theme.scss';
@import '../../../../styles/layout.scss';

.app-color-add {
    position: relative;
    padding: 40px 30px;
    padding-top: 30px;
    display: flex;
    flex-wrap: wrap;
    width: 500px;
    max-width: 100%;
    h3, p, button, input, select {
        width: 100% !important;
    }
    p {
        margin-top: 10px;
        margin: 0;
        margin-top: 15px !important;
    }
    input, select {
        margin: 0 !important;
    }
    button {
        margin-top: 25px;
    }
}
@media screen and (min-width: 0) {
    .app-color-add-btn {
        width: 100%;
    }
}
@media screen and (min-width: $app-layout-md-breakpoint) {
   .app-color-add-btn {
      width: auto;
    }
}