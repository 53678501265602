@import '../../../../../styles/theme.scss';


.app-chromacity-form {
  max-width: 100%;
  width: 450px;
  position: relative;
  overflow: hidden;

  display: flex;
  flex-wrap: wrap;
  padding-bottom: 20px;

  $app-chromacity-form-title-height: 40px;
  .app-chromacity-form-title {
    width: 100%;
    font-size: 18px;
    font-weight: bold;
    padding: 15px;
    padding-bottom: 5px;

    display: inline-block;
  }
  .app-chromacity-form-content {
    width: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    
    display: flex;
    flex-wrap: wrap;

    input, select {
      width: 100%;
    }

    .app-chromacity-form-content-div {
      padding: 20px;
      padding-top: 10px;
      padding-bottom: 0;
      width: 100%;
    }

    .app-chromacity-form-content-div50 {
      display: inline-block;
      width: 50%;
      padding: 20px;
      padding-top: 10px;
      padding-bottom: 0;

      &:nth-child(odd) {
        padding-right: 10px;
      }
      &:nth-child(even) {
        padding-left: 10px;
      }
    }

    button {
      width: 100%;
    }
  }
}