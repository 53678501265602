@import '../../../styles/theme.scss';


.app-json-object-edit {
  min-width: 320px;
  width: 400px;
  position: relative;
  overflow: hidden;

  display: flex;
  flex-direction: column;
  padding-bottom: 20px;

  $app-json-object-edit-title-height: 40px;
  .app-json-object-edit-title {
    font-size: 18px;
    font-weight: bold;
    padding: 15px;
    padding-bottom: 5px;

    display: inline-block;
  }
  .app-json-object-edit-content {
    overflow-x: hidden;
    overflow-y: auto;
    
    display: flex;
    flex-direction: column;

    input, select {
      width: 100%;
    }
  }
}