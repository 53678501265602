@import '../../../styles/theme.scss';
@import '../../../styles/layout.scss';

.app-color {
  display: flex;
  flex-wrap: wrap;
  position: relative;

  width: 100%;


  /*Nav Map*/
  .app-color-list {  
    position: relative;
        
    background: white;
    border: 1px solid rgb(202, 202, 202);      
    box-shadow: 0 0 20px rgb(0 0 0 / 15%);
    -webkit-box-shadow: 0 0 20px rgb(0 0 0 / 15%);
    -ms-box-shadow: 0 0 20px rgba(0,0,0,.15);

    display: flex;
    flex-direction: column;

    .app-nav-map-beadcrumb {
      padding: 15px;
      padding-bottom: 10px;
      margin-top: 0;
  
      display: inline-block;
    }

    .app-color-list-wrapper {
      position: relative;
      flex-grow: 1;
    }
    .app-color-list-buttons {
      width: 100%;
      padding: 10px;
      display: flex;
      button {
        min-width: 100%;
      }
    }
  }


  /*Color info + Chromaticity/Wavelength*/
  .app-color-data {  
    position: relative;

    display: flex;
    flex-direction: column;

    .app-color-data-content, .app-color-data-header {
      width: 100%;
        
      background: white;
      border: 1px solid rgb(202, 202, 202);      
      box-shadow: 0 0 20px rgb(0 0 0 / 15%);
      -webkit-box-shadow: 0 0 20px rgb(0 0 0 / 15%);
      -ms-box-shadow: 0 0 20px rgba(0,0,0,.15);
    }

    .app-color-data-content {
      flex-grow: 1;
      overflow: hidden;

      .app-chromaticity, .app-wavelength {
        max-height: 100%;
        display: flex;
        .app-data-view {
          flex-grow: 1;
        }
      }
      .app-chromaticity {
        .app-data-view-content {
          min-width: 800px;
        }
      }
    }

    .app-color-data-header {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      margin-bottom: 20px;

      .app-color-data-header-title {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin: 15px;
        margin-bottom: 0;
        width: 100%;
        
        .app-color-data-header-title-text {
          flex-grow: 1;
          margin-top: 0;
          
          font-size: 20px;
          font-weight: bold;


          .app-color-data-header-title-text-active {
            font-size: 22px;
            color: $app-theme-secondary;
          }
        }

        .app-color-data-header-title-button {
          padding: 15px;
          button {
            width: 100%;
            padding: 7px 15px;
            margin-bottom: 10px;
          }
        } 
      }
    }
  }

  .app-color-forwardvoltage, .app-color-flux {  
    position: relative;
        
    background: white;
    border: 1px solid rgb(202, 202, 202);      
    box-shadow: 0 0 20px rgb(0 0 0 / 15%);
    -webkit-box-shadow: 0 0 20px rgb(0 0 0 / 15%);
    -ms-box-shadow: 0 0 20px rgba(0,0,0,.15);

    display: flex;
    flex-direction: column;
  }
}

@media screen and (min-width: 0) {
  .app-color { 
    
    .app-color-used-in {
      width: 100%;
    }
    
    .app-color-list {    
      margin: 20px;
      
      height: 300px;
      width: 100%;
    }
    
    .app-color-data {
      margin: 20px;
      margin-top: 0;  

      height: 500px;
      width: calc(100% - 40px);
    }

    .app-color-forwardvoltage, .app-color-flux { 
      height: 300px;
    }
    .app-color-forwardvoltage { 
      width: calc(100% - 40px);
      margin: 0 20px 20px 20px;

      .app-forward-voltage {
        height: 100%;
        .app-data-view {
          height: 100%;
        }
      }
    }
    .app-color-flux { 
      width: calc(100% - 40px);
      margin: 0 20px 20px 20px;

      .app-flux {
        height: 100%;
        .app-data-view {
          height: 100%;
        }
      }
    }
  }
      
  .app-color-data-header-subtitle {
    margin-left: 15px;
    margin-top: 10px;
    margin-bottom: 15px;
  }
}
@media screen and (min-width: $app-layout-md-breakpoint) {
  .app-color { 
    .app-color-used-in {
      width: auto;
    }
  }
}
@media screen and (min-width: $app-layout-lg-breakpoint) {
  .app-color {  

    flex-direction: row-reverse;

    .app-color-list {  
      margin: 20px;
      
      height: 450px;
      width: 300px;
    }
    
    .app-color-data {
      margin: 20px;
      
      margin-right: 0;
      height: 450px;
      width: calc(100% - 360px);
    }

    .app-color-forwardvoltage, .app-color-flux { 
      height: 300px;
    }
    .app-color-forwardvoltage { 
      width: calc(50% - 30px);
      margin: 0 20px 20px 0;
    }
    .app-color-flux { 
      width: calc(50% - 30px);
      margin: 0 20px 20px 20px;
    }
  }
      
  .app-color-data-header-subtitle {
    margin-left: 15px;
    margin-top: 3px;
    margin-bottom: 15px;
  }
  
}

.app-color-warn-no-color {
  font-size: 20px;
  margin: auto;
  color: gray;
  text-align: center;
  margin-top: 150px;
}

.app-color-usedin {
  min-width: 400px;
}