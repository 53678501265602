@import '../../../styles/theme.scss';


.app-nav-map {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  
  display: flex;
  flex-direction: column;

  .app-nav-map-filter {
    width: 100%;
  }
  .app-nav-map-items {
    width: 100%;
    flex-grow: 2;
    overflow-x: hidden;
    overflow-y: auto;

  }

  input {
    display: inline-block;
    width: calc(100% - 30px);
    margin: 20px 15px;
    margin-bottom: 10px;
  }

  .app-nav-map-beadcrumb {
    font-weight: bold;
    margin-top: 10px;
    //color: $app-theme-secondary;
    cursor: pointer;
    padding: 15px 20px;
  }
  .app-nav-map-item {
    position: relative;
    cursor: pointer;
    padding: 15px 20px;
    text-align: left;
    border-bottom: 1px solid #ddd;

    img {
      width: 14px;
      position: absolute;
      right: 5px;
      top: 20px;
    }

    &:first-of-type {
      border-top: 1px solid #ddd;
    }
    &.active {
      color: $app-theme-secondary;
    }

    &:hover {
      background: rgb(253, 253, 253);
    }
  }
}